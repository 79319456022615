@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap");

.App {
  min-height: 100vh;
  display: flex;
  background-image: url("./background-img-2.jpeg");
  background-size: cover;
  background-position: center;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.281);
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
